<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
// import axios from "axios";
import Swal from "sweetalert2";

export default {
    page: {
        title: "Ketersediaan",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Ketersediaan",
            items: [
                {
                    text: "Verifikasi TUK",
                    href: "/verifikasi-tuk",
                },
                {
                    text: "Ketersediaan",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,
            showAlasan: false,
        };
    },
    mounted() {
    },
    methods: {
        changeKetersediaan(event) {
            let self = this;
            var data_ketersediaan = event.target.value;
            if (data_ketersediaan == 'bersedia') {
                self.showAlasan = false;
                Swal.fire({
                    title: 'Apakah Anda Yakin?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Ya',
                    denyButtonText: `Tidak`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        self.$router.push({ name: "detail-verifikasi_tuk_tugas", params: { id: 1 } });
                    } else if (result.isDenied) {
                        Swal.close();
                    }
                })
            } else {
                self.showAlasan = true;
            }
        },
        submitAlasan(){
            let self = this;
            self.$router.push({ name: "all-verifikasi_tuk" });
        }
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group">
                            <label>Apakah Anda Bersedia?</label><br>
                            <input type="radio" name="ketersediaan" value="bersedia" @change="changeKetersediaan($event)">
                            Bersedia &nbsp;
                            <input type="radio" name="ketersediaan" value="tidak_bersedia"
                                @change="changeKetersediaan($event)"> Tidak Bersedia
                        </div>
                        <div v-if="showAlasan">
                            <div class="form-group">
                                <label for="">Alasan</label>
                                <textarea name="" id="" cols="30" rows="10" class="form-control"></textarea>
                            </div>
                            <div class="form-group">
                                <button class="btn btn-primary mt-3" @click="submitAlasan">Simpan</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
